<template>
  <el-menu
    style="display: inline-block; margin-left: 10px"
    mode="horizontal"
    router
    :default-active="$route.path"
  >

    <MenuItem
      v-show="$canAndRulesPass(user, 'home.see_in_menu')"
      index="/"
      :route="{name: 'Home'}"
    >
      Главная
    </MenuItem>

    <el-submenu
      v-show="showAdministration"
      index="1"
    >
      <template slot="title">Администрирование</template>

      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.RBAC.LIST)"
        index="/settings/rbac"
        :route="{name: 'Rbac'}"
      >
        RBAC
      </MenuItem>

      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.USER.CHIEFS_LIST_BY_TYPE)"
        index="/settings/chiefs"
        :route="{name: 'Chiefs'}"
      >
        Оргструктура
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.GROUP.LIST)"
        index="/settings/groups"
        :route="{name: 'Groups'}"
      >
        Группы
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.REPORT.USE)"
        index="/report"
        :route="{name: 'Report'}"
      >
        Конструктор отчетов
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.MASS_ACTIONS.CREATE)"
        index="/settings/mass-actions"
        :route="{name: 'MassActions'}"
      >
        Массовые действия
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, 'scales.see_units')"
        index="/catalog/units"
        :route="{name: 'Units'}"
      >
        Единицы измерения
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, 'evaluations.see_in_menu')"
        index="/catalog/evaluations"
        :route="{name: 'Evaluations'}"
      >
        Оценки
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, 'home.edit')"
        index="/settings/home"
        :route="{name: 'HomeSettings'}"
      >
        Главная
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.USER.DEPUTIES_SEE_IN_MENU)"
        index="/settings/deputies"
        :route="{name: 'Deputies'}"
      >
        Делегирование доступа
      </MenuItem>
<!--      <MenuItem-->
<!--        v-show="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"-->
<!--        index="/settings/dynamic"-->
<!--        :route="{name: 'DynamicSettings'}"-->
<!--      >-->
<!--        Прочее-->
<!--      </MenuItem>-->
    </el-submenu>


    <el-submenu
      v-show="$canAndRulesPass(user, $permissions.CARD.LIST) || $canAndRulesPass(user, 'calibration.see_in_menu') || $canAndRulesPass(user, $permissions.LIBRARY.SEE_IN_MENU)"
      index="4"
    >
      <template slot="title">Управление эффективностью и карьерой</template>

      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.LIBRARY.SEE_IN_MENU)"
        v-for="(typeConfig, typeKey) in $companyConfiguration.libraries.getTypes()"
        :key="typeKey"
        :index="'/library/'+typeKey"
        :route="{name: 'Library', params: { type: typeKey }}"
      >
        {{ typeConfig.showName }}
      </MenuItem>

      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.CARD.LIST)"
        index="/cards"
        :route="{name: 'Cards'}"
      >
        Карты целей
      </MenuItem>

      <MenuItem
        v-show="$canAndRulesPass(user, 'calibration.see_in_menu')"
        index="/calibration"
        :route="{name: 'Calibration'}"
      >
        Калибровка оценок
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, 'resources_evaluation.see_in_menu')"
        index="/human-resources-evaluation"
        :route="{name: 'HumanResourcesEvaluation'}"
      >
        Оценка кадрового потенциала
      </MenuItem>
    </el-submenu>

    <MenuItem
      v-show="$canAndRulesPass(user, $permissions.MATRIX.VIEW)"
      index="/matrix"
      :route="{name: 'Matrix'}"
    >
      Матрица КПЭ
    </MenuItem>
    <MenuItem
      v-show="$canAndRulesPass(user, $permissions.USER.ORGSTRUCT_VIEW)"
      index="/orgstruct"
      :route="{name: 'Orgstruct'}"
    >
      Орг. структура
    </MenuItem>




    <MenuItem
      v-show="$canAndRulesPass(user, 'successors.see_in_menu')"
      index="/successors"
      :route="{name: 'Successors'}"
    >
      Планы преемственности
    </MenuItem>


    <el-submenu
      v-show="$canAndRulesPass(user, 'compensations.procedure_see_in_menu') || $canAndRulesPass(user, 'compensations.plan_see_in_menu')"
      index="5"
    >
      <template slot="title">Компенсации</template>

      <MenuItem
        v-show="$canAndRulesPass(user, 'compensations.procedure_see_in_menu')"
        index="/compensation/procedures"
        :route="{name: 'CompensationProcedures'}"
      >
        Управление процессами
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, 'compensations.plan_see_in_menu')"
        index="/compensation/procedure/plans"
        :route="{name: 'CompensationProcedurePlans'}"
      >
        Планы
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, 'compensations.report_see_in_menu')"
        index="/compensation/procedure/report"
        :route="{name: 'CompensationProcedureReport'}"
      >
        Отчеты
      </MenuItem>


    </el-submenu>




  </el-menu>
</template>

<script>
import {mapGetters} from "vuex";
import MenuItem from "@/components/header/MenuItem.vue";

export default {
  name: "menu-component",
  components: {MenuItem},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
    showAdministration(){
      return this.$canAndRulesPass(this.user, this.$permissions.RBAC.LIST)
          || this.$canAndRulesPass(this.user, this.$permissions.USER.CHIEFS_LIST_BY_TYPE)
          || this.$canAndRulesPass(this.user, 'setting.scales')
          || this.$canAndRulesPass(this.user, this.$permissions.GROUP.LIST)
          || this.$canAndRulesPass(this.user, this.$permissions.MASS_ACTIONS.CREATE)
          || this.$canAndRulesPass(this.user, this.$permissions.SETTING.UPDATE)
          || this.$canAndRulesPass(this.user, this.$permissions.SETTING.UPDATE)
          || this.$canAndRulesPass(this.user, 'scales.see_units')
          || this.$canAndRulesPass(this.user, 'evaluations.see_in_menu')
    },
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>


<style lang="scss">

</style>